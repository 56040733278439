import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { AuthService } from "@core/services/auth/auth.service";
import { PreferenceDashboard } from "@shared/schemas/preference-dashboard";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class PreferencesService {
	API_BFF_URL = "";

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {
		this.API_BFF_URL = `${config.environment.API_BFF}`;
	}

	getDashboardPreferences(
		preferenceName: string
	): Observable<PreferenceDashboard> {
		const params = new HttpParams()
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			)
			.set("preference", preferenceName);

		return this.http.get<PreferenceDashboard>(
			`${this.API_BFF_URL}/users/user-preference`,
			{ params, withCredentials: true }
		);
	}

	newDashboardPreference(
		preferenceName: string,
		valor: boolean
	): Observable<unknown> {
		return this.http.post<unknown>(
			`${this.API_BFF_URL}/users/user-preference`,
			{
				preference: preferenceName,
				value: valor,
				hashedFingerprint: this.authService.getHashedFingerprint()
			},
			{
				withCredentials: true
			}
		);
	}
}
