export enum KeyDashbordPreferences {
	MENU_ENCOLHIDO = "MENU_ENCOLHIDO",
	DASHBOARD_TOP_10_MAIS = "DASHBOARD_TOP_10_MAIS",
	DASHBOARD_AGENDA = "DASHBOARD_AGENDA",
	DASHBOARD_FATSERVICOS = "DASHBOARD_FATSERVICOS",
	DASHBOARD_FATVENDAS = "DASHBOARD_FATVENDAS",
	DASHBOARD_FATPDV = "DASHBOARD_FATPDV",
	DASHBOARD_FATTICKETNFCE = "DASHBOARD_FATTICKETNFCE",
	DASHBOARD_FATTICKETPEDIDOS = "DASHBOARD_FATTICKETPEDIDOS",
	DASHBOARD_ESTOQUE = "DASHBOARD_ESTOQUE",
	DASHBOARD_INDICESGRAFICOS = "DASHBOARD_INDICESGRAFICOS",
	DASHBOARD_PEDIDOS_NOTAS = "DASHBOARD_PEDIDOS_NOTAS",
	DASHBOARD_META_VENDAS = "DASHBOARD_META_VENDAS",
	DASHBOARD_DADOS_FINANCEIROS = "DASHBOARD_DADOS_FINANCEIROS",
	DASHBOARD_ANIVERSARIO = "DASHBOARD_ANIVERSARIO",
	DASHBOARD_PROD_VALID = "DASHBOARD_PROD_VALID"
}
